.container {
  height: 100%;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.iconContainer {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blue-20);
  font-size: 3rem;
  color: var(--blue-80)
}