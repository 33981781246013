.container {
  height: 100%;
  width: 100%;
  padding-top: 4.375rem;
  background-color: rgba(255, 255, 255, .5);
  border-right: 1px solid var(--gray-20)
}

.listItem {
  height: 3.75rem;
  padding: 0rem .75rem;
  border-bottom: 1px solid var(--gray-10);
  cursor: pointer;
  display: flex;
  align-items: center !important;
  justify-content: space-between !important;
}

.listItem:last-child {
  border-bottom: 1px solid transparent;
}

.listItemTitle {
  display: flex;
  align-items: center !important;
  gap: 0.5rem !important;
}

.active {
  background-color: var(--cta--blue) !important;
  color: white !important;
}

.active .listItemTitle {
  color: white !important;
}

@media (max-width: 576px) {
  .container {
    padding-top: 0;
    display: flex;
    border: none;
  }

  .listItem {
    background-color: white;
    width: 25%;
    border-right: 1px solid var(--background-elevation-0);
    border-bottom: none;
    height: 3rem
  }

  .listItem:last-child {
    border-right: none;
  }

  .listItemTitle {
    margin: auto;
    text-align: center;
  }
}