.container {
  background-color: var(--gray-10);
  padding: .75rem;
  border-radius: 5px;
  position: relative;
}

.toggle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}

.options {
  overflow: hidden;
  transition: height .2s ease-in-out;
}