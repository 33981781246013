.container {
  background-color: white;
  border: 1px solid var(--gray-20);
  border-radius: 10px;
  width: auto;
  display: inline-flex;
  flex-flow: column;
  flex-grow: 1;
  align-self: stretch;
  position: relative;
}

.overlay {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  background-color: var(--background-elevation-1);
  opacity: .5;
  z-index: 10;
  border: 1px solid var(--background-elevation-1);
  border-radius: 10px;
}

.disabled {
  opacity: .3;
  pointer-events: none;
}

.fakeButton {
  width: 69px;
  height: 27px;
  border-radius: 5px;
  background-color: var(--blue-40);
}

.titles {
  border-bottom: 1px solid var(--gray-20);
  padding: .75rem
}

.titles * {
  margin-bottom: 0
}

.body {
  flex: 1;
  padding: .75rem;
}

.footer {
  border-top: 1px solid var(--gray-5);
  padding: .75rem;
  color: var(--cta--blue) !important;
  /*text-align: right;*/
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-transform: uppercase;
  text-decoration: none !important;
}

.footer * {
  text-decoration: none !important;
}