.remove {
  color: var(--red);
  font-weight: bold;
  cursor: pointer;
  position: relative;
}

.clickArea {
  position: absolute;
  top: -.75rem;
  right: -.75rem;
  bottom: -.75rem;
  left: -.75rem;
}