.container {
  background-color: white;
  border: 1px solid var(--gray-20);
  border-radius: 5px;
  margin-bottom: 1rem;
}

.titles {
  padding: .75rem;
  transition: all .3s ease-in-out;
  border-bottom: 1px solid transparent
}

.open .titles {
  border-bottom: 1px solid var(--gray-20)
}

.typeName {
  font-size: .75rem;
  color: var(--gray-60);
  transition: all .3s ease-in-out;
}

.value {
  font-size: 1rem;
  transition: all .3s ease-in-out;
  font-weight: bold;
}

.open .titles .typeName {
  font-size: 1rem;
}

.open .titles .value {
  font-size: 0rem;
}

.editor {
  transition: all .3s ease-in-out;
  overflow: hidden;
  padding: 0 .75rem
}

.inputsContainer {
  padding-top: 0.75rem;
  padding-bottom: 0.25rem;
}

.remove {
  transition: all .3s ease-in-out;
  font-size: 0;
  color: var(--red);
  font-weight: bold;
  cursor: pointer;
  pointer-events: none;
  position: relative;
}

.open .remove {
  font-size: 1rem;
  pointer-events: auto;
}

.clickArea {
  position: absolute;
  top: -.75rem;
  right: -.75rem;
  bottom: -.75rem;
  left: -.75rem;
}