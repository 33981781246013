.input {
  border: 1px solid var(--gray-20);
  border-radius: 10px;
  padding: 1rem;
  height: 72px;
}

input:focus {
  padding-bottom: 0
}

.label {
  font-size: var(--caption);
  font-weight: bold;
  color: var(--gray-60);
}

.buttonGroup {
  border: 1px solid var(--gray-20);
  border-radius: 10px;
  overflow: hidden;
}

.tooltipWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}