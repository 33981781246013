.flowChartContainer {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 500px;
  max-width: 100%;
}

.timelineContainer {
  width: 900px;
  max-width: 80vw
}

.timelineEmptyState {
  display: flex;
  gap: .75rem;
  height: 100%;
  width: 100%;
}

@media (max-width: 576px) {
  .flowChartContainer {
    width: 80vw;
  }

  .timelineEmptyState {
    flex-direction: column-reverse;
  }

  .timelineContainer {
    width: 80vw;
  }
}