.container {
  border-radius: 30px;
  background-color: var(--background-elevation-1);
  /* TODO */
  padding: 1.5rem;
}

.scroller {
  /* bs container padding + this container padding */
  margin-left: calc(-1.5rem + -.75rem);
  margin-right: calc(-1.5rem + -.75rem);
}

.scrollerInner {
  position: relative;
}

.cards {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  overflow-x: scroll;
  /* bs container padding + this container padding */
  padding-left: calc(1.5rem + .75rem);
  padding-right: calc(1.5rem + .75rem);
}

.cards::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.cards>* {
  flex-grow: 0 !important;
}

.description {
  max-width: 900px;
}