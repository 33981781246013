.container {
  background-color: white;
  border: 1px solid var(--gray-20);
  border-radius: 10px;
  padding: .75rem;
  margin-bottom: .75rem;
  height: 100%;
}

@media (max-width: 768px) {
  .container {
    border: none
  }
}
