.container {
  width: 2.75rem;
  height: 2.75rem;
  position: relative;
  background-color: var(--gray-10);
  border-radius: calc(2.75rem / 2);
}


.icon {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-weight: bold;
}

.footerIcon {
  position: absolute;
  bottom: -2px;
  right: -2px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background-color: var(--gray-60);
  border-radius: 3px;
  color: white;
}