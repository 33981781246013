.date {
  display: flex;
  border: 1px solid var(--gray-20);
  border-radius: 10px;
  overflow: hidden;
}

.datePart {
  flex-basis: 0;
  flex-grow: 1;
}

.label {
  font-size: var(--caption);
  font-weight: bold;
  color: var(--gray-60);
}

.label > select {
  border: none !important;
  border-radius: 0;
}

.input {
  border: 1px solid var(--gray-20);
  border-radius: 10px;
  padding: 1rem;
  height: 72px;
}

input:focus {
  padding-bottom: 0
}