.logo {
  max-height: 25px;
  margin-right: 5px
}

.container {
  background: white;
  border-bottom: 1px solid var(--gray-20);
  z-index: 100000000;
}

.back {
  padding: .75rem;
  padding-left: 0;
  cursor: pointer;
}

.inner {
  height: var(--navbar-height)
}