.container {
  background-color: rgba(255, 255, 255, .4);
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 15px;
  padding: 1rem;
}

.CTA {
  cursor: pointer;
  display: flex;
  border-style: dashed;
  min-height: 200px;
  color: var(--cta--blue);
  font-weight: bold;
  align-items: center !important;
  justify-content: center !important;
}