.sidebarContainer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 25rem;
}

.main {
  padding: 1.5rem;
  padding-left: calc(1.5rem + 25rem);
  padding-bottom: 0px;
}

.sectionContainer {
  padding-top: 5rem;
}

@media (max-width: 992px) {
  .sidebarContainer {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 18rem;
  }

  .main {
    padding: 1.5rem;
    padding-left: calc(1.5rem + 18rem)
  }
}

@media (max-width: 767px) {
  .sectionContainer {
    padding-top: 2.5rem;
  }
}

@media (max-width: 576px) {
  .sidebarContainer {
    top: calc(var(--navbar-height) + 1px);
    position: sticky;
    width: 100%;
    z-index: 10000;
  }

  .main {
    padding-left: 1.5rem
  }
}