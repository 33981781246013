.container {
  background-color: var(--gray-5);
  padding: .5rem .75rem;
  border-radius: 10px;
  cursor: pointer;
  border: 2px solid var(--gray-5);
  transition: border .1s ease-in-out, opacity .1s ease-in;
  min-height: 60px;
  opacity: 1;
}

.container.selected {
  border: 2px solid var(--cta--blue)
}

.icon {
  background-color: var(--green-dark);
  border-radius: 20px;
  width: 20px;
  height: 20px;
  position: relative;
  flex-shrink: 0;
}

.icon:not(.reached) {
  background-color: var(--orange-dark);
}

.icon.placeholder {
  background-color: var(--blue-80);
}

.iconInner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  color: white
}

.fade {
  opacity: .6;
}