.container {
  position: relative;
}

.iconContainer {
  font-size: 1rem;
}

.panel {
  z-index: 1000000;
  position: fixed;
  padding: .75rem;
  background-color: var(--gray-20);
  border-radius: 5px;
  display: none;
  min-width: 200px;
  max-width: 400px;
}

.panel.toggled {
  display: flex;
}


.arrowRight {
  position: absolute;
  left: 100%;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid var(--gray-20);
}

.arrowLeft {
  position: absolute;
  right: 100%;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid var(--gray-20);
}

.panel.up .arrowLeft,
.panel.up .arrowRight {
  bottom: 10px
}

.panel.down .arrowLeft,
.panel.down .arrowRight {
  top: 10px
}

.body {
  overflow-y: auto;
  padding-right: .75rem;
  max-height: 100%;
}