.container {
  background-color: var(--gray-5);
  color: var(--gray-60);
  border-radius: 10px;
  padding: 1rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cta {
  color: var(--cta--blue);
  padding-top: .75rem;
}

.button {
  margin-top: 1.5rem;
}

.iconContainer {
  font-size: 1.375rem;
}