:root {
  /* Color styles */
  --white: #FFFFFF;
  --black: #17191C;
  --cta--blue: #275FFF;
  --background-elevation-0: #EEF3F6;
  --background-elevation-1: #F5F8FA;
  --blue-20: #EAF0FF;
  --blue-40: #CBD9FF;
  --blue-60: #7D9FFF;
  --blue-80: #224ABB;
  --green: #39C82D;
  --green-light: #D7F4D5;
  --green-dark: #1B8811;
  --red: #D81C32;
  --red-light: #F7D1D6;
  --red-dark: #9E1B2B;
  --orange: #FE6610;
  --orange-light: #FFE0CF;
  --orange-dark: #C04B08;
  --gray-5: #F7F7F8;
  --gray-10: #EFEFF1;
  --gray-20: #D5D7DC;
  --gray-40: #BABDC5;
  --gray-60: #5D626F;
  --gray-80: #3A3D45;
  --turquoise: #24C4B1;
  --turquoise-light: #A8E7E0;
  --turquoise-dark: #209184;
  --sky: #27A4FF;
  --sky-light: #A9DBFF;
  --sky-dark: #227BBB;
  --purple: #7341FE;
  --purple-light: #C7B3FF;
  --purple-dark: #5735BA;
  --violet: #D241FE;
  --violet-light: #EDB3FF;
  --violet-dark: #9A35BA;
  --pink: #FF6FB0;
  --pink-light: #FFC5DF;
  --pink-dark: #BA5584;
  /* Text-size styles */

  /* Text-size styles */
  /* base size: body (16px) */
  --hero: 6rem;
  --hero-small: 4.25rem;
  --section-title: 3rem;
  --headline-4: 2.12rem;
  --headline-5: 1.5rem;
  --headline-6: 1.25rem;
  --subtitle-1: 1.12rem;
  --subtitle-2: 0.88rem;
  --body: 1rem;
  --body-bold: 1rem;
  --body-small: 0.88rem;
  --body-small-bold: 0.88rem;
  --caption: 0.75rem;
  --caption-bold: 0.75rem;
  --caption-small: 0.62rem;

  /*--hero: 96px;*/
  /*--hero-small: 68px;*/
  /*--section-title: 48px;*/
  /*--headline-3: 16px;*/
  /*--headline-4: 34px;*/
  /*--headline-5: 24px;*/
  /*--headline-6: 20px;*/
  /*--subtitle-1: 18px;*/
  /*--subtitle-2: 14px;*/
  /*--body: 16px;*/
  /*--body-bold: 16px;*/
  /*--body-small: 14px;*/
  /*--body-small-bold: 14px;*/
  /*--caption: 12px;*/
  /*--caption-bold: 12px;*/
  /*--caption-small: 10px;*/

  --bold: 700;
  --semi-bold: 600;

  --gradient-cash-primary: linear-gradient(109.3deg, #209184 0%, #50D0C0 100%);
  --gradient-cash-secondary: linear-gradient(124.08deg, #D3E9E7 0%, #DCF6F2 100%);

  --gradient-investment-primary: linear-gradient(109.3deg, #5735BA 0%, #7DC9FF 100%);
  --gradient-investment-secondary: linear-gradient(109.3deg, #DED7F1 0%, #E5F4FF 100%);

  --gradient-stock-primary: linear-gradient(109.3deg, #227BBB 0%, #7DC9FF 100%);
  --gradient-stock-secondary: linear-gradient(124.08deg, #D3E5F2 0%, #E5F4FF 100%);

  --gradient-bond-primary: linear-gradient(109.3deg, #5735BA 0%, #AB8DFF 100%);
  --gradient-bond-secondary: linear-gradient(124.08deg, #DED7F2 0%, #EEE8FF 100%);

  --gradient-income-primary: linear-gradient(109.3deg, #209959 0%, #95C8AD 100%);
  --gradient-income-secondary: linear-gradient(124.08deg, #D3EBDE 0%, #EAF4EE 100%);

  --gradient-expense-primary: linear-gradient(109.3deg, #EB8D99 0%, #9E1B2B 100%);
  --gradient-expense-secondary: linear-gradient(124.08deg, #FBE8EB 0%, #ECD2D5 100%);

  --gradient-outflow-primary: linear-gradient(180deg, rgba(235, 141, 153, 1) 0%, rgba(235, 141, 153, 1) 80%, rgba(255, 255, 255, 1) 100%);

  --gradient-savings-primary: linear-gradient(90deg, #209184 0%, #7DC9FF 100%);
  --gradient-savings-secondary: linear-gradient(90deg, #C7E3D4 13.43%, #B1DAD8 72.18%);

  --gradient-extra-3-secondary: linear-gradient(124.08deg, #D5D7DC 0%, #F7F7F8 100%);

  --navbar-height: 3.75rem;
}

html {
  font-size: 16px;
}

@media (max-width: 1200px) {
  html {
    font-size: 14px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-elevation-0);
  overflow-y: scroll;
  min-height: 100vh;
  font-size: var(--body);
}

.small {
  font-size: var(--body-small) !important;
}

.fw-semi-bold {
  font-weight: var(--semi-bold);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.clickable {
  cursor: pointer
}

.subtitle-1 {
  font-size: var(--subtitle-1) !important;
  font-weight: bold !important;
}

.subtitle-2 {
  font-size: var(--subtitle-2) !important;
  font-weight: var(--semi-bold);
}

.headline-4 {
  font-size: var(--headline-4) !important;
  font-weight: bold !important;
}

.headline-5 {
  font-size: var(--headline-5) !important;
  font-weight: bold !important;
}

.headline-6 {
  font-size: var(--headline-6) !important;
  font-weight: 700 !important;
}

.caption {
  font-size: var(--caption) !important;
}

.caption-small {
  font-size: var(--caption-small) !important;
}

.caption-bold {
  font-size: var(--caption) !important;
  font-weight: 600;
}

.body-small {
  font-size: var(--body-small) !important;
}

.body-bold {
  font-size: var(--body-bold);
  font-weight: var(--semi-bold);
}

.body-small-bold {
  font-size: var(--body-small-bold);
  font-weight: 600;
}

.bg-base {
  background-color: var(--background-elevation-0);
}

.bg-elevation-1 {
  background-color: var(--background-elevation-1);
}

.bg-gradient-cash-secondary {
  background: var(--gradient-cash-secondary);
}

.bg-gradient-savings-primary {
  background: var(--gradient-savings-primary);
}

.text-cta {
  color: var(--cta--blue)
}

.text-cta-secondary {
  color: var(--blue-60)
}

.text-inflow {
  color: var(--green-dark)
}

.text-outflow {
  color: var(--red)
}

.text-red {
  color: var(--red);
}

.text-income {
  background: var(--gradient-income-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-expense {
  background: var(--red-dark);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-saving {
  background: var(--gradient-savings-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

hr {
  opacity: 1;
  background-color: var(--gray-20);
}

.text-gray-40 {
  color: var(--gray-40) !important
}

.text-gray-60 {
  color: var(--gray-60) !important
}

.text-gray-80 {
  color: var(--gray-80) !important
}

.text-green {
  color: var(--green) !important
}


input,
select {
  border: 1px solid var(--gray-20) !important;
}

input:disabled,
select:disabled {
  border: 1px solid rgba(0, 0, 0, 0.03) !important;
  background-color: rgba(0, 0, 0, 0.03) !important;
}

button:focus,
button:active:focus,
button:visited,
button:hover,
button:active {
  outline: none !important;
  box-shadow: none !important;
}

.bg-light {
  background-color: var(--gray-10);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.form-floating>.form-control:focus,
.form-floating>.form-control:placeholder-shown {
  padding-top: 1.625rem !important;
  padding-bottom: 0.625rem !important;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:placeholder-shown~label,
.form-floating>.form-select~label {
  opacity: .65 !important;
  transform: scale(.85) translateY(-0.5rem) translateX(0.15rem) !important;
}

::-webkit-input-placeholder {
  color: var(--gray-40) !important;
}

:-moz-placeholder {
  color: var(--gray-40) !important;
}

::-moz-placeholder {
  color: var(--gray-40) !important;
}

:-ms-input-placeholder {
  color: var(--gray-40) !important;
}

input::placeholder {
  color: var(--gray-40) !important;
}

textarea::-webkit-input-placeholder {
  color: var(--gray-40) !important;
}

textarea:-moz-placeholder {
  color: var(--gray-40) !important;
}

textarea::-moz-placeholder {
  color: var(--gray-40) !important;
}

textarea:-ms-input-placeholder {
  color: var(--gray-40) !important;
}

textarea::placeholder {
  color: var(--gray-40) !important;
}