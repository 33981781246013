
.breakdownTitle {
  color: var(--gray-60);
  font-size: var(--subtitle-1);
  font-weight: 600;
}

.breakdownItem {
  color: var(--gray-60);
  font-size: var(--subtitle-2);
  font-weight: 600;
}

.chartContainer {
  display: flex;
  gap: 1rem;
}

@media (max-width: 767px) {
  .chartContainer {
    flex-direction: column;
  }
}

.chart {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  z-index: 1;
}

.chart canvas {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.chartSum {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 0;
  font-weight: 700;
}

.minusSign {
  position: absolute;
  top: 0;
  left: -10px;
}

.divider {
  border-right: 1px solid #D5D7DC;
}

.stackContainer {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  width: 100%;
}

@media (max-width: 992px) {
  .chartSum {
    font-size: 1.5rem !important;
  }
}

@media (max-width: 767px) {
  .chart {
    width: 50%;
    padding-bottom: 50%;
  }

  .chartSum {
    font-size: 2.12rem !important;
  }

  .divider {
    border-right: 0;
  }

  .stackContainer {
    width: 50%;
    padding: 2rem;
  }

}

@media (max-width: 576px) {
  .chart {
    width: 100%;
    padding-bottom: 100%;
  }

  .stackContainer {
    width: 100%;
  }
}

@media (max-width: 414px) {
  .chartSum {
    font-size: 1.5rem !important;
  }
}