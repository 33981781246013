.container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.col {
  width: 100%;
  max-width: 100%;
  padding: 0 .75rem;
  margin-bottom: .75rem
}

@media (min-width: 576px) {
  .col {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col {
    max-width: 50%
  }
}

@media (min-width: 992px) {
  .col {
    max-width: 50%;
  }
}

@media (min-width: 1200px) {
  .col {
    max-width: 40%;
  }
}

@media (min-width: 1500px) {
  .col {
    max-width: 500px;
  }
}