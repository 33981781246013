.enter {
  opacity: 0;
  transform: translateX(5%);
}
.enterActive {
  opacity: 1;
  transform: translateX(0);  
  transition: all .2s ease-in;
}
.exit {
  opacity: 1;
  transform: translateX(0);
}
.exitActive {
  opacity: 0;
  transform: translateX(5%);
  transition: all .2s ease-in;
}