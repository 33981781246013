.container {
  z-index: 10000000000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  overflow: auto;

}

.modal {
  background-color: white;
  border-radius: 30px;
  padding: 4rem 6rem 3rem 6rem;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

@media (max-width: 576px) {
  .modal {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 4rem 3rem 3rem 3rem;
  }
}