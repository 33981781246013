.container {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 300px;
  padding-bottom: 100%;
}

.innerContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  gap: .25rem;
}

.col {
  display: flex;
  flex-direction: column;
  gap: .25rem;
  flex: 1;
}

.stack {
  flex: 1;
  min-height: calc(32px + 1.5rem);
  border-radius: 10px;
  padding: .75rem;
}