.container {
  padding: .75rem
}

.canvasContainer {
  height: 300px;
  position: relative;
  padding-top: 60px;
  /* overflow: hidden; */
}

.canvasContainer canvas {
  display: block;
}

.point {
  display: none;
  z-index: 10;
  position: absolute;
  background-color: var(--cta--blue);
  border: 2px solid white;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  right: -11px;
  transform: translate(-50%, -50%);
}

.horizontalLabels {
  /* border-top: 2px solid var(--gray-20); */
  padding-top: .2rem;
  color: var(--gray-40);
  font-weight: bold;
  box-sizing: border-box;
}

.verticalLabels {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  color: var(--gray-40);
  font-weight: bold;
}

.yearStripes {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
}

.yearStripe {
  background-color: rgba(0, 0, 0, 0.0);
  flex: 1 0 0;
  border-right: 1px dashed transparent;
  border-left: 1px dashed transparent;
  position: relative;
}

.yearStripe.active {
  background-color: rgba(0, 0, 0, 0.02);
  /* border-right: 1px dashed var(--gray-10); */
}

.line {
  border-right: 1px dashed transparent;
  position: absolute;
  bottom: 0;
  right: -1.5px;
  height: 0px;
}

.redLine {
  border-right: 1px dashed var(--orange-dark);
  position: absolute;
  bottom: 0;
  right: -1.5px;
  height: 0px;
  z-index: 0;
  opacity: 0.2;
  transition: height .2s ease-in-out
}

.redDot {
  z-index: 100;
  position: absolute;
  background-color: var(--orange-dark);
  border: 2px solid white;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  bottom: -10px;
  right: -11px;
  transform: translate(-50%, -50%);
}

.yearStripe.active .line {
  border-right: 1px dashed var(--cta--blue);
}

.yearStripe.active .point {
  display: block;
}

.yearStripePanel {
  transition: all .1s ease-out;
  z-index: 10000;
  display: flex;
  position: absolute;
  top: calc(100% + 5px);
  transform: translate(-15px, 0px);
  padding: .5rem;
  background-color: var(--background-elevation-1);
  border-radius: 5px;
}

.yearStripePanel.active {
  display: flex;
}

.panelIcon {
  text-align: center;
  display: inline-block;
  width: 15px;
}

.connect {
  position: absolute;
  bottom: calc(100% + 2px);
  height: 5px;
  border-left: 2px solid var(--gray-40);
  transform: translateX(-50%);
  left: 50%;
  z-index: 0;
}

.goalPoint .connect {
  border-left: 2px solid var(--green-dark);
}

.goalPoint .connect.retirement {
  border-left: 2px solid var(--violet-dark);
}

.goalPoint .connect:not(.reached) {
  border-left: 2px solid var(--orange-dark)
}

.goalIcons {
  bottom: calc(100% + 6px);
  z-index: 100;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.effectIcons {
  bottom: calc(100% + 6px);
  z-index: 100;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.effectIconWrapper,
.goalIconWrapper {
  position: relative;
  width: 5px;
  height: 25px;
  transition: all .2s ease-in-out;
}

.goalPoint,
.effectPoint {
  z-index: 100;
  position: absolute;
  background-color: var(--gray-40);
  border: 2px solid white;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  transform: translate(-50%, -50%);
  bottom: 0px;
  left: 0px;
  transition: bottom .2s ease-in-out, left .2s ease-in-out
}

.effectPoint {
  bottom: -9px;
  left: 100%;
}

.goalPoint {
  background-color: var(--green-dark);
}

.goalPoint.retirement {
  background-color: var(--violet-dark);
}

.goalPoint:not(.reached) {
  z-index: 99;
  background-color: var(--orange-dark);
}

.goalPoint:hover {
  z-index: 10000;
}

.goalPoint:hover .goalIconWrapper {
  width: 25px
}

.goalIcon,
.effectIcon {
  position: absolute;
  background-color: var(--gray-40);
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border: 2px solid white;
  color: white;
  font-size: 10px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.goalIcon {
  background-color: var(--green-dark);
}

.goalIcon.retirement {
  background-color: var(--violet-dark);
}

.goalIcon div,
.effectIcon div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.goalIcon:not(.reached) {
  background-color: var(--orange-dark);
}

.goalIconTitles,
.effectIconTitles {
  white-space: nowrap;
  width: auto;
  min-width: 200px;
  display: none;
  flex-direction: column;
  position: absolute;
  bottom: 50%;
  padding: .5rem;
  font-size: .75rem;
  border: 2px solid var(--gray-40);
  background-color: var(--background-elevation-1);
  color: var(--gray-60);
  border-radius: 5px;
}

.leftSided {
  right: 50%;
}

.rightSided {
  left: 50%;
}

.goalIconTitles {
  border: 2px solid var(--green-light);
  background-color: var(--background-elevation-1);
  color: var(--green-dark);
}

.goalIconTitles.retirement {
  border: 2px solid var(--violet-light);
  background-color: #FDF8FF;
  color: var(--violet-dark);
}

.goalPoint:hover .goalIconTitles,
.effectPoint:hover .effectIconTitles {
  display: flex;
}

.goalPoint:not(.reached) .goalIconTitles {
  color: var(--orange-dark);
  background-color: var(--orange-light);
  border-color: var(--orange-light);
}

.goalIconTitles.fixed {
  display: flex;
  text-align: right;
  padding: .75rem;
}

.goalIconTitles.fixed > div > div:nth-child(2) {
  font-size: 1rem;
  font-weight: bold;
}

.effect {
  position: absolute;
  bottom: -0.5px;
  height: 2.5px;
  left: -1px;
  right: -1px;
  transition: all .1s ease-in-out;
  background-color: var(--gray-60);
}

.topbarContainer {
  padding: .75rem;
  background-color: var(--gray-5);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--body-small)
}