.container {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.375rem;
  margin-left: -0.375rem;
  margin-top: -.75rem
}

.suggestionWrapper {
  float: left;
  width: 33.333333%;
  padding-left: .375rem;
  padding-right: .375rem;
  margin-top: .75rem;
}

.suggestion {
  font-size: var(--caption);
  background-color: white;
  cursor: pointer;
  border: 1px solid var(--gray-20);
  border-radius: 10px;
  height: 80px;
  padding: .5rem;
  text-align: center;
  font-weight: 600;
}

.suggestion:hover {
  color: var(--cta--blue);
  border: 1px solid var(--cta--blue);
}