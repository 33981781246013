.timelineWrapper {
  background-color: transparent;
}

@media (max-width: 768px) {
  .timelineWrapper {
    padding: .75rem;
    background-color: white;
    border-radius: 10px;
  }
}