.container {
  background-color: var(--blue-20); 
  /* border: 1px solid var(--gray-10); */
  border-radius: 10px;
  padding: .75rem;
}

.editor {
  overflow-x: visible;
  overflow-y: hidden;
  max-height: 0px;
  transition: max-height .2s ease-in-out;
  margin: 0 -1rem;
}

.chevron {
  transition: transform .2s ease-in-out;
  transform: rotate(0deg);
}

.container:not(.open) .chevron {
  transform: rotate(180deg);
}