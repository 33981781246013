.container {
  background-color: var(--background-elevation-0);
  width: 25rem;
  position: fixed;
  top: var(--navbar-height);
  left: 0;
  bottom: 0;
  z-index: 1000000;
  padding: .75rem;
  border-right: 1px solid var(--gray-10);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: .75rem;
}


@media (max-width: 768px) {
  .container {
    display: none;
    width: 100%;
    background-color: white;
    top: 0;
    padding: 0;
    z-index: 10000000000;
  }

  .container.open {
    display: flex;
  }
}