.base {
  border-radius: 30px !important;
  background-color: rgba(0, 0, 0, .05);
  color: black;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.incomes {
  background-color: var(--green-light);
  color: var(--green-dark);
}

.expenses, .debts {
  background-color: var(--red-light);
  color: var(--red-dark);
}

.assets {
  background-color: var(--blue-20);
  color: var(--blue-80);
}

.active {
  background-color: rgba(255, 255, 255, .1) !important;
  color: white !important;
}

.goals {
  background-color: var(--green-dark);
  color: white;
}

.goals.negative {
  background-color: var(--orange-dark);
  color: white;
}

.savings {
  background-color: var(--green-light);
  color: var(--black);
}

.savings.negative {
  background-color: var(--red-light);
  color: var(--black);
}

.retirement {
  background-color: var(--violet-dark);
  color: white;
}

.retirement.negative {
  background-color: var(--red-dark);
  color: white;
}