.container {
  background: white;
  border-radius: 10px;
}

.container.active {
  border: 2px solid var(--cta--blue)
}

@media (max-width: 768px) {
  .container.active {
    border: none
  }
}