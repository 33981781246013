.container {
  padding: .75rem 0;
  --savings-height: calc(40px + 1.5rem);
}

.chart {
  flex-shrink: 0;
  width: 500px;
  position: relative;
  height: 220px;
}

.savingsSmaller {
  --savings-height: calc(40px + 1.5rem)
}

.savingsEqual {
  --savings-height: calc(115px - 1.5rem)
}

.savingsGreater {
  --savings-height: calc(90px + 1.5rem)
}

.inflow {
  color: white;
  padding: .75rem;
  font-size: .875rem;
  box-sizing: border-box;
  top: 5px;
  left: 0;
  position: absolute;
  background: var(--gradient-income-primary);
  height: 180px;
  width: 150px;
  border: 2px solid white;
  border-radius: 10px;
  z-index: 10;
}

.mask {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 185px;
  bottom: 5px;
  background-color: white;
  width: 210px;
}

.outflow {
  color: black;
  padding: .75rem;
  padding-left: calc(.75rem + 5px);
  font-size: .875rem;
  box-sizing: border-box;
  z-index: 1;
  left: 140px;
  top: calc(var(--savings-height) + 3px);
  right: 170px;
  position: absolute;
  background: var(--gradient-outflow-primary);
  bottom: 5px;
  border: 2px solid white;
  border-top-right-radius: 60px;
}

.savingsGreater .outflow {
  border-top-right-radius: 30px;
}

.savingsGreater .mask {
  width: 250px;
}

.savingsEqual .outflow {
  border-top-right-radius: 45px;
}

.savingsEqual .mask {
  width: 230px;
}

.savingsConnection {
  box-sizing: border-box;
  top: 5px;
  position: absolute;
  z-index: 2;
  left: 140px;
  background: var(--gradient-savings-secondary);
  border: 2px solid white;
  height: var(--savings-height);
  right: 160px;
}

.savings {
  color: white;
  padding: .75rem;
  font-size: .875rem;
  background: var(--gradient-savings-primary);
  top: 5px;
  position: absolute;
  z-index: 10;
  height: var(--savings-height);
  border: 2px solid white;
  right: 15px;
  width: 190px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.savingsEqual .savings {
  right: 20px;
}

.savingsGreater .savings {
  right: 30px;
}

.savingsArrow {
  position: absolute;
  left: 100%;
  top: 50%;
  height: calc(var(--savings-height) + 10px);
  object-fit: fill;
  transform: translateY(-50%);
}

