.avatar-container {
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}

.avatar {
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--blue-40);
  position: relative;
  border-radius: 50%
}

.initials {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-weight: bold;
}