.container {
  background-color: white;
  padding: .75rem;
  padding-left: calc(.75rem + 25rem);
  min-height: calc(100vh - var(--navbar-height) - 1px)
}

@media (max-width: 992px) {
  /* .container {
    padding-left: calc(.75rem + 21rem);
  } */
}

@media (max-width: 768px) {
  .container {
    padding-left: .75rem;
    background-color: transparent;
  }
}

