.iconContainer {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.positive {
  background-color: var(--green-light);
  color: var(--green)
}

.negative {
  background-color: var(--red-light);
  color: var(--red)
}